import React from "react";

export default function Hero() {
  return (
    <>
      <div className="hero">
        <div className="container">
          <div className="area">
            <p>Discover Chitradurga's Heritage and Comfort</p>
            <h1>Hotel Aishwarya Fort</h1>
            <span>Experience the warm hospitality</span>
          </div>
        </div>
      </div>
    </>
  );
}
