import React from "react";
import Button from "./Button";

export default function CTA() {
  return (
    <>
      <div className="cta">
        <div className="container">
          <div className="cont">
            <h1>Book Your Stay Today</h1>
            <p>
              Experience the <b>Aishwarya Fort</b> difference.
            </p>
            <p>Book your stay now and embark on an unforgettable journey.</p>
            <Button
              title="Book A Stay"
              url="https://wa.me/919620537978/"
              className="pt-3"
            />
          </div>
        </div>
      </div>
    </>
  );
}
