import React from "react";
import About from "../Assets/img/abimg.png";
import Button from "./Button";

export default function AboutFort() {
  return (
    <>
      <div className="about">
        <div className="d-flex align-items-center gap-3 mobdisplay">
          <img src={About} alt="" />
          <div className="abarea px-5">
            <h4>
              Welcome to Aishwarya Fort - a beacon of hospitality in Chitradurga
              for the past 25 years!
            </h4>
            <p>
              Experience the perfect blend of heritage and modernity at
              Aishwarya Fort, the best hotel in Chitradurga. Nestled in the
              heart of the city, just moments away from the iconic Chitradurga
              Fort, our hotel offers unparalleled comfort, exceptional service,
              and a true taste of Karnataka’s rich culture.
            </p>
            <Button title="Know More" url="/" className="pt-2" />
          </div>
        </div>
      </div>
    </>
  );
}
