import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import RoomImage1 from "../../Assets/img/home/room1.svg"; // Example room image
import RoomImage2 from "../../Assets/img/home/room2.svg";
import RoomImage3 from "../../Assets/img/home/room3.svg";
import RoomImage4 from "../../Assets/img/home/room4.svg";
import { MdFreeBreakfast } from "react-icons/md";
import { FaWifi, FaTv } from "react-icons/fa";
import { TbAirConditioning } from "react-icons/tb";
import Button from "../Button";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import SimilarRooms from "../SimilarRooms";

export default function DeluxeSuite() {
  const roomDetails = {
    title: "A/C Deluxe Suite",
    description:
      "Experience the height of luxury in our A/C Deluxe Suite, featuring a king-size bed, spacious living area, and stunning views of the city.",
    price: 2500,
    amenities: [
      { icon: <FaWifi />, name: "Free Wi-Fi" },
      { icon: <FaTv />, name: "Flat-screen TV" },
      { icon: <TbAirConditioning />, name: "Air Conditioning" },
      { icon: <MdFreeBreakfast />, name: "Complimentary Breakfast" },
    ],
    images: [RoomImage1, RoomImage2, RoomImage3, RoomImage4],
    btnUrl: "https://wa.me/919620537978/",
  };

  return (
    <>
      <div className="section pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <p>
                  <Link to="/rooms">
                    <IoIosArrowBack /> Back
                  </Link>
                </p>
                <div className="hotel-room">
                  <div className="room-image">
                    <Carousel
                      showThumbs={true}
                      autoPlay
                      infiniteLoop
                      showStatus={false}
                      showArrows={true}
                    >
                      {roomDetails.images.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`${roomDetails.title} - ${index + 1}`}
                            className="img-fluid"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className="room-details">
                    <h2 className="room-title">{roomDetails.title}</h2>
                    <p className="room-description">
                      {roomDetails.description}
                    </p>
                    <div className="room-price">
                      <h4>
                        Rs. {roomDetails.price} <span>/ night</span>
                      </h4>
                      <p className="note">
                        All rates are excluding GST of 12% + Extra Bed A/C Rs.
                        500/ night
                      </p>
                    </div>
                    <div className="room-amenities">
                      <h5>Amenities:</h5>
                      <ul>
                        {roomDetails.amenities.map((amenity, index) => (
                          <li key={index} className="amenity">
                            <i>{amenity.icon}</i>
                            <span>{amenity.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Button
                      url={roomDetails.btnUrl}
                      title="Book Now"
                      className="pt-3"
                    />
                    <SimilarRooms />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
