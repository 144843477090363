import React from "react";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { MdPhoneInTalk } from "react-icons/md";
import CTA from "../Components/CTA";

export default function Contact() {
  const contactDetails = {
    mobile: "+919620537978",
    email: "aishwaryafort2000@gmail.com",
    address:
      "Hotel Aishwarya Fort, Turuvanur Rd, near RTO Office, Maniyur, Chitradurga, Karnataka 577501",
    whatsapp: "+919620537978",
    receptionLandline: "08194231823",
  };

  return (  
    <>
      <div className="contact-section">
        <div className="container">
          <h4>Feel free to reach out to us!</h4>
          <ul className="contact-list pt-4">
            <li className="contact-item">
              <FaPhoneAlt />
              <span>
                Mobile:{" "}
                <a href={`tel:${contactDetails.mobile}`}>
                  {contactDetails.mobile}
                </a>
              </span>
            </li>
            <li className="contact-item">
              <FaEnvelope />
              <span>
                Email:{" "}
                <a href={`mailto:${contactDetails.email}`}>
                  {contactDetails.email}
                </a>
              </span>
            </li>
            <li className="contact-item">
              <FaMapMarkerAlt />
              <span>Address: {contactDetails.address}</span>
            </li>
            <li className="contact-item">
              <FaWhatsapp />
              <span>
                WhatsApp:{" "}
                <a
                  href={`https://wa.me/${contactDetails.whatsapp.replace(
                    /\s+/g,
                    ""
                  )}`}
                >
                  {contactDetails.whatsapp}
                </a>
              </span>
            </li>
            <li className="contact-item">
              <MdPhoneInTalk />
              <span>
                Reception Landline:{" "}
                <a href={`tel:${contactDetails.receptionLandline}`}>
                  {contactDetails.receptionLandline}
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <CTA />
    </>
  );
}