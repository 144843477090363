import React from "react";
import Hero from "../Components/Hero";
import AboutFort from "../Components/AboutFort";
import Culinary from "../Components/Culinary";
import Stay from "../Components/Stay";
import Enjoy from "../Components/Enjoy";
import Explore from "../Components/Explore";
import CTA from "../Components/CTA";

export default function Home() {
  return (
    <>
      <Hero />

      <AboutFort />

      <Culinary />

      <Stay />

      <Enjoy />

      <Explore />

      <CTA />
    </>
  );
}
