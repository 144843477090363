import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Room1 from "../Assets/img/room1.png";
import Button from "./Button";

export default function Stay() {
  return (
    <>
      <div className="stay section">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <p>
                <span>
                  Looking for the best place to stay in Chitradurga? Aishwarya
                  Fort is your ultimate destination. Our luxury hotel in
                  Chitradurga is designed to cater to travelers seeking an
                  authentic experience, whether you’re here for business,
                  leisure, or a special occasion. With over 25 years of
                  excellence in hospitality, we promise a stay that exceeds your
                  expectations.
                </span>
              </p>
              <Button
                url="https://wa.me/919620537978/"
                title="Book A Stay"
                className="pt-3"
              />
            </div>
            <div className="col-md-9">
              <div className="staying">
                <h4>Discover Chitradurga's Finest Accommodation</h4>
                <div className="row">
                  <div className="col-md-6">
                    <Link to="/rooms/familysuite">
                      <div className="item">
                        <img src={Room1} alt="" className="img-fluid" />
                        <div className="items">
                          A/C FAMILY SUITE <MdKeyboardArrowRight />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    <Link to="/rooms/deluxesuite">
                      <div className="item">
                        <img src={Room1} alt="" className="img-fluid" />
                        <div className="items">
                          A/C DELUXE SUITE <MdKeyboardArrowRight />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-md-6">
                    <Link to="/rooms/executive">
                      <div className="item">
                        <img src={Room1} alt="" className="img-fluid" />
                        <div className="items">
                          EXECUTIVE A/C ROOM <MdKeyboardArrowRight />
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-6">
                    <Link to="/rooms/superior">
                      <div className="item">
                        <img src={Room1} alt="" className="img-fluid" />
                        <div className="items">
                          SUPERIOR NON A/C <MdKeyboardArrowRight />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
