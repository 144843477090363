import "./App.css";
import "./Assets/styles/custom.scss";
import "./Assets/styles/responsive.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";
import Culinary from "./Components/Culinary";
import Stay from "./Components/Stay";
import Explore from "./Components/Explore";
import Enjoy from "./Components/Enjoy";
import Executive from "./Components/rooms/Executive";
import DeluxeSuite from "./Components/rooms/DeluxeSuite";
import FamilySuite from "./Components/rooms/FamilySuite";
import Superior from "./Components/rooms/Superior";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dining" element={<Culinary />} />
          <Route path="/rooms" element={<Stay />} />
          <Route path="/celebrate" element={<Enjoy />} />
          <Route path="/explore" element={<Explore />} />

          <Route path="/rooms/deluxesuite" element={<DeluxeSuite />} />
          <Route path="/rooms/familysuite" element={<FamilySuite />} />
          <Route path="/rooms/executive" element={<Executive />} />
          <Route path="/rooms/superior" element={<Superior />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
