import React from "react";
import E1 from "../Assets/img/e1.png";
import E2 from "../Assets/img/e2.png";
import E3 from "../Assets/img/e3.png";
import E4 from "../Assets/img/e4.png";
import E5 from "../Assets/img/e5.png";
import E6 from "../Assets/img/e6.png";
import E7 from "../Assets/img/e7.png";
import E8 from "../Assets/img/e8.png";
import Button from "./Button";

export default function Explore() {
  return (
    <>
      <div className="exp section ">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h4>Explore With Us</h4>
              <p>
                <span>
                  Unwind in our luxurious rooms and suites, designed to pamper
                  your senses. Immerse yourself in comfort and elegance as you
                  rejuvenate for your journey.
                </span>
              </p>
              <Button
                url="https://wa.me/919620537978/"
                title="Book A Stay"
                className="pt-3"
              />
            </div>
            <div className="col-md-9 mobtop">
              <h5>Let us be your guide to this enchanting land</h5>
              <div className="row">
                <div className="col-md-3">
                  <img src={E1} alt="" className="img-fluid" />
                </div>
                <div className="col-md-3">
                  <img src={E2} alt="" className="img-fluid" />
                </div>
                <div className="col-md-3">
                  <img src={E3} alt="" className="img-fluid" />
                </div>
                <div className="col-md-3">
                  <img src={E4} alt="" className="img-fluid" />
                </div>
                <div className="col-md-3">
                  <img src={E5} alt="" className="img-fluid" />
                </div>
                <div className="col-md-3">
                  <img src={E6} alt="" className="img-fluid" />
                </div>
                <div className="col-md-3">
                  <img src={E7} alt="" className="img-fluid" />
                </div>
                <div className="col-md-3">
                  <img src={E8} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
