import React from "react";
import Food from "../Assets/img/food.png";
import Culi from "../Assets/img/culi.png";
import Quote from "../Assets/img/quote.svg";

export default function Culinary() {
  return (
    <>
      <div className="culinary section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <h4>A Culinary Symphony</h4>
              <p>
                Savor the essence of Chitradurga on your palate. Our restaurant
                offers a delightful fusion of traditional and contemporary
                flavors. From hearty breakfasts to exquisite dinners, every meal
                is a culinary masterpiece.
              </p>
              <img src={Food} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <img src={Culi} alt="" className="img-fluid" />
              <div className="quote">
                <img src={Quote} alt="" />{" "}
                <span>Indulging in flavors that make life delicious</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
